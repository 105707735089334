<template>
  <div>
    <AppBar :title="$t('cashiers.title')"/>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-card-title>
        <v-btn depressed color='secondary' to='/cashiers/add' rounded>{{ $t('cashiers.add') }}</v-btn>
      </v-card-title>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class='text-left'>{{ $t('users.name') }}</th>
            <th class='text-left'>{{ $t('users.surname') }}</th>
            <th class='text-left'>{{ $t('users.email') }}</th>
            <th class='text-left'>{{ $t('cashiers.shoppingCenter') }}</th>
            <th class='text-left'>{{ $t('cashiers.showPersonalData') }}</th>
            <th class='text-left'>{{ $t('users.active') }}</th>
            <th style='width: 100px'></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='cashier in cashiers' :key='cashier.id'>
            <td>{{ cashier.name }}</td>
            <td>{{ cashier.surname }}</td>
            <td>{{ cashier.email }}</td>
            <td>{{ cashier.shoppingCenter }}</td>
            <td>
              <v-switch v-model='cashier.isPersonalDataVisible' @change='changePersonalDataVisibility(cashier)' color='secondary' class='mt-0' hide-details></v-switch>
            </td>
            <td>
              <v-switch v-model='cashier.isActive' @change='changeStatus(cashier)' color='secondary' class='mt-0' hide-details></v-switch>
            </td>
            <td>
              <v-btn depressed @click="$router.push({ name: 'EditCashier', params: { id: cashier.id } })" rounded>{{ $t('cashiers.edit') }}</v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'Cashiers',
  data () {
    return {
      cashiers: []
    }
  },
  created () {
    this.getCashiers()
  },
  methods: {
    async getCashiers () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      try {
        const { data } = await Service.get(`/Employees/Cashiers?countryCode=${countryCode}`)
        this.cashiers = data.map(cashier => ({ ...cashier, isActive: !cashier.blocked }))
      } catch (error) {}
      this.loading = false
    },
    async changeStatus (cashier) {
      if (cashier.blocked) {
        await this.unblockCashier(cashier.id)
      } else {
        await this.blockCashier(cashier.id)
      }
    },
    async changePersonalDataVisibility (cashier) {
      if (cashier.isPersonalDataVisible) {
        await this.showPersonalData(cashier.id)
      } else {
        await this.hidePersonalData(cashier.id)
      }
    },
    async blockCashier (id) {
      this.loading = true
      await Service.put(`/Employees/${id}/Block`)
      await this.getCashiers()
      this.loading = false
    },
    async unblockCashier (id) {
      this.loading = true
      await Service.put(`/Employees/${id}/Unblock`)
      await this.getCashiers()
      this.loading = false
    },
    async showPersonalData (id) {
      this.loading = true
      await Service.put(`/Employees/${id}/ShowPersonalData`)
      await this.getCashiers()
      this.loading = false
    },
    async hidePersonalData (id) {
      this.loading = true
      await Service.put(`/Employees/${id}/HidePersonalData`)
      await this.getCashiers()
      this.loading = false
    }
  }
}
</script>
